//酒店管理api

import req from "../utils/req"

//筛选酒店接口
export function choose_api(params) {
    return req.get("/product/hotel/filterHotel", params)
}

//查询一个酒店
export function serachHotel_api(param) {
    return req.get("/product/hotel/getById/{id}", param)
}
//查询一个酒店--新
export function serachHoteltwo_api(param) {
    return req.get("/product/hotel/getNewHotel", param)
}


//查询运营助理
export function serachTag_api(param) {
    return req.get("/product/hotel/selectAssistant", param)
}

//获取审核酒店列表
export function getAudit_api(param) {
    return req.get("/otaHotel/hotelSon/getListExamine", param)
}

//获取待下线酒店列表
export function getLine_api(param) {
    return req.get("/otaHotel/hotelSon/tapeDownline", param)
}
//待开发 / 黑名单 酒店列表
export function getDevelop_api(param) {
    return req.get("/product/hotel/getHotelToBeDevelopedOrBlacklist", param)
}

//确认审核酒店通过
export function getPass_api(param) {
    return req.get("/otaHotel/hotelSon/setExamine", param)
}

//获取携程马甲
export function getVest_api(param) {
    return req.get("/otaHotel/hotelSon/getCtripVest", param)
}

/* Meiriya */
//待匹配酒店列表
export function getMatching_api(param) {
    return req.get("/otaHotel/hotelSonVest/getList", param)
}
//携程手动匹配确定
export function manualMatching_api(param) {
    return req.get("/otaHotel/hotelSonVest/releaseSingleVest", param)
}
//获取艺龙匹配列表
export function getYiLongMatchingList_api(param) {
    return req.get("/otaHotel/hotelSonVest/getSameCityHotelList", param)
}
//获取飞猪匹配列表
export function getFeizhuMatchingList_api(param) {
    return req.get("/otaHotel/matching/getFlyPigHotel", param)
}
// 绑定飞猪酒店
export function manualFeizhu_api(param) {
    return req.get("/otaHotel/matching/matchingHotel", param)
}
//获取组别列表
export function getGroupList_api(param) {
    return req.get("/otaHotel/hotelSon/getOperationsAssistantGroup", param)
}
// 美团酒店匹配失败重新提交
export function getMatchingMtHotel(param) {
    return req.get("/otaHotel/matching/matchingMtHotel", param)
}
// 酒店发布时的平台马甲查询
export function getReleasePlatformVestList(param) {
    return req.get("/otaHotel/hotelSon/getReleasePlatformVestList", param)
}

// 酒店发布时的平台马甲设置
export function setReleasePlatformVestList(param) {
    return req.postlg("/otaHotel/hotelSon/setReleasePlatformVestList", param)
}

// 获取酒店信息
export function getHotelInfo(param) {
    return req.postlg("/otaHotel/hotelSon/getHotelInfo", param)
}

//核心 / 一般 酒店列表 qk
export function getCore_api(param) {
    return req.get("/otaHotel/hotelSon/getCoreOrOffenList", param)
}

// 下线酒店 / 核心酒店  qk
export function setBatchoffline_api(param) {
    return req.get("/otaHotel/hotelSon/setBatchoffline", param)
}

// 交接酒店 / 人员组别  qk
export function gethotelHandover_api(param) {
    return req.get("/otaHotel/hotelSon/gethotelHandover", param)
}

// 设置交接管理 qk
export function sethotelHandover_api(param) {
    return req.get("/otaHotel/hotelSon/sethotelHandover", param)
}

// 获取运营助理 / 助理列表  qk
export function getOperationsAssistant_api(param) {
    return req.get("/otaHotel/hotelSon/getOperationsAssistant", param)
}

// 获取运营助理 / 助理列表  qk
export function setOperationsAssistant_api(param) {
    return req.get("/otaHotel/hotelSon/setOperationsAssistant", param)
}
// 酒店售卖状态设置
export function hotelShelves_api(param) {
    return req.postlg("/otaHotel/hotelSon/hotelShelves", param)
}
// 获取银行列表
export function getBankList_api(param) {
    return req.get("/otaHotel/hotelSon/getBankList", param)
}
// 获取银行列表
export function matchingMtPoi_api(param) {
    return req.get("/otaHotel/matching/matchingMtPoi", param)
}
// 待审核条数
export function getExamineCount_api(param) {
    return req.get("/otaHotel/hotelSon/getExamineCount", param)
}
// 删除匹配失败的马甲匹配记录
export function deleteMatchingFailVest_api(param) {
    return req.get("/otaHotel/hotelSonVest/deleteMatchingFailVest", param)
}
// 获取酒店相关人员
export function gethotelManagerList_api(param) {
    return req.get("/otaHotel/manager/getHotelManager", param)
}

// 设置保留天数
export function setRetentionDays_api(param) {
    return req.get("/otaHotel/hotelSon/setRetentionDays", param)
}

// 删除了的酒店列表
export function deletedList_api(param) {
    return req.get("/otaHotel/hotelSon/getCoreOrOffenList1", param)
}

//获取所有城市
export function getAllCity_api(param) {
    return req.get("/otaHotel/city-info/getList", param)
}
//刷新艺龙数据
export function hotelStaticInfo_api(param) {
    return req.get("/SameCity/statcData/hotelStaticInfo", param)
}

//天下房仓酒店列表
export function getFangcangMatchingList_api(param) {
    return req.get("/otaHotel/hotelSonVest/getFangHotelHotelList", param)
}



